import { useEffect, useState } from 'react'
import './Register.style.css'
import { resetNewPasswordService } from '../../common/apiServices/auth/auth.service'

export default function ResetNewPasswordModal({
    title,
    setShowModalResetNewPassword,
    tokenResetNewPassword,
    setshowModalLoginClient
}) {

    const [newPasswordData, setNewPasswordData] = useState({
        newPassword: "",
        confirmPassword: "",
        token: ""
    })
    const [msgErrorConfirmPassword, setMsgErrorConfirmPassword] = useState("")
    const [msgSuccessConfirmPassword, setMsgSuccessConfirmPassword] = useState("")
    const [errorIdentical, setErrorIdentical] = useState("")

    const handleChangeNewPasswordReset = (event) => {
        const { name, value } = event.target;
        // For other fields, just update the value as usual
        setNewPasswordData({
            ...newPasswordData,
            [name]: value
        });
    }

    const handleSubmitNewPasswordReset = (event) => {
        event.preventDefault();
        const {newPassword, confirmPassword} = newPasswordData;

        if (newPassword !== confirmPassword) {
            setErrorIdentical('vos mots de passe sont pas identique !');
            return; // here return dosn't allow execution of next code
        }

        // call api service Login
        resetNewPasswordService(newPasswordData)
        .then((data) => {
            if(data && data.code === 'INVALID'){
                setMsgErrorConfirmPassword("Le token de votre réinitialisation de mot de passe a été expiré !")
            }else{
                setNewPasswordData({
                    newPassword: "",
                    confirmPassword: ""
                })
                setMsgErrorConfirmPassword("")
                setErrorIdentical("")
                setMsgSuccessConfirmPassword("Votre Nouveau mot de passe à été changé !")
                setTimeout(() => {
                    setShowModalResetNewPassword(false);
                    setshowModalLoginClient(true)
                }, 2000)
            }
        })
    }

     // Sync token with state if it changes
  useEffect(() => {
    setNewPasswordData((prev) => ({
      ...prev,
      token: tokenResetNewPassword
    }));
  }, [tokenResetNewPassword]);

    return (
        <div
            className="modal fade show"
            id="shareModal"
            tabindex="-1"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
            style={{
                // this stye what make modal display
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div class="modal-dialog"
                style={{
                    maxWidth: '80vw',
                    width: '400px',
                    margin: "auto",
                }}
            >
                <div class="modal-content"
                    style={{ borderColor: "none", }}
                >
                    <div class="modal-header">
                        <h5
                            class="modal-title"
                            id="shareModalLabel"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: 23,
                                fontWeight: 600
                            }}
                        >{title}</h5>
                        <img
                            src="images/close-icon.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowModalResetNewPassword(false)}
                        />
                    </div>
                    <div class="modal-body">
                        <div class="d-grid gap-2">
                            <form onSubmit={handleSubmitNewPasswordReset}>
                                <div className='mb-3'>
                                    <label className='fw-semibold' htmlFor='emailId'>Mot de passe*</label>
                                    <input type='password' className='form-control focus-warning' name="newPassword" required id="newPasswordId" value={newPasswordData?.newPassword}
                                        onChange={handleChangeNewPasswordReset}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label className='fw-semibold' htmlFor='emailId'>Confirmer votre mot de passe*</label>
                                    <input type='password' className='form-control focus-warning' name="confirmPassword" required id="confirmPassworddId" value={newPasswordData?.confirmPassword}
                                        onChange={handleChangeNewPasswordReset}
                                    />
                                </div>
                                {
                                    errorIdentical && <p className='text text-danger text-small'>{errorIdentical}</p>
                                }
                                {
                                    msgErrorConfirmPassword && <p className='text text-error text-small'>{msgErrorConfirmPassword}</p>
                                }
                                {
                                    msgSuccessConfirmPassword && <p className='text text-success text-small'>{msgSuccessConfirmPassword}</p>
                                }
                                <div class="d-flex justify-content-center">
                                    <button type="submit" class="btn btn-warning m-1 fw-semibold">
                                        Continuer
                                    </button>
                                    <button type="button" class="btn btn-outline-warning m-1 fw-semibold"
                                        onClick={() => setShowModalResetNewPassword(false)}
                                    >Annuler</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
