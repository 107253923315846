import { API_URL } from "../../../env"

export const verifyValidityCouponService = async (bodeyObj) => {
  return fetch(`${API_URL}/api-bo/coupons/verify-validity/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodeyObj),
  })
    .then((res) => res.json())
}

export const sendEmailMiamusService = async (bodyObj) => {
  return fetch(`${API_URL}/api/miamus-coupon/handle-process-by-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyObj),
  })
    .then((res) => res.json())
    .catch((err) => err)
}

export const verifyMiamusCouponService = async (bodyObj) => {
  return fetch(`${API_URL}/api/miamus-coupon/verify-validity`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyObj),
  })
    .then((res) => res.json())
}

export const getMiamusDiscountsWithSettings = async () => {
  return fetch(`${API_URL}/api/miamus-coupon/settings`)
    .then((res) => res.json())
    .catch((err) => err)
}

export const createCouponService = async (bodyObj) => {
  return fetch(`${API_URL}/api/miamus-coupon/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyObj),
  })
    .then((res) => res.json())
    .catch(err => err)
}