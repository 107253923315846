import React, { useEffect } from 'react'
import { useState } from 'react';
import { addToCart } from '../../redux/cartSlice';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../env';
import PacksProductSection from '../../components/packs/PacksProductSection';
import SupplementsProduct from '../../components/supplements/SupplementsProduct';
import SuggestionsProduct from '../../components/suggestions/SuggestionsProduct';
import { useNavigate } from 'react-router-dom';
import { countAllTotalsOfSingleProduct, formatPrice } from '../helpers/utils';

export default function GenericSingleProduct({ product }) {
  const [quantityProduct, setQuantityProduct] = useState(1);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [supplementsData, setSupplementsData] = useState([]);
  const [suggestionsData, setSuggestionsData] = useState([]);
  const [packsData, setPacksData] = useState([]);
  const [tmpPlusProduct, setTmpPlusProduct] = useState({ ...product });
  const [countAllTotalOfSingleProduct, setCountAllTotalOfSingleProduct] = useState(null);


  const incrementQnt = () => {
    setQuantityProduct(quantityProduct + 1);
  }
  const decrementQnt = () => {
    if (quantityProduct > 1) {
      setQuantityProduct(quantityProduct - 1);
    }
  }

  const handleCheckedSuppliments = (checkedSupplements) => {
    setSupplementsData(checkedSupplements);
    setTmpPlusProduct({
      ...tmpPlusProduct,
      supplements: checkedSupplements
    })
  }

  const handleCheckedSuggestions = (checkedSuggestions) => {
    setSuggestionsData(checkedSuggestions);
    setTmpPlusProduct({
      ...tmpPlusProduct,
      suggestions: checkedSuggestions
    })
  }

  const handleCheckedPacks = (checkedPacks) => {
    setPacksData(checkedPacks);
    setTmpPlusProduct({
      ...tmpPlusProduct,
      packs: checkedPacks
    })
  }

  const addProductToCart = (prod, quant) => {
    let product = { ...prod, quantity: quant };
    let storageCart = JSON.parse(localStorage.getItem('cart')) || { products: [], count: 0 };

    // Ensure storageCart.products is an array
    storageCart.products = storageCart.products || [];

    let foundProduct = storageCart.products.find((item) => item.id === product.id);

    if (foundProduct) {
      // If the product is found, update its quantity and supplements with suggetsions & packs
      foundProduct.quantity += quant;

      foundProduct.supplements = foundProduct.supplements || [];
      foundProduct.suggestions = foundProduct.suggestions || [];
      foundProduct.packs = foundProduct.packs || [];

      foundProduct.supplements = [...foundProduct.supplements, ...supplementsData];
      foundProduct.suggestions = [...foundProduct.suggestions, ...suggestionsData];
      foundProduct.packs = [...foundProduct.packs, ...packsData];

      storageCart = {
        ...storageCart,
        count: storageCart.count + quant
      };
    } else {
      // If the product is not found, add it to the cart
      product.supplements = product.supplements || [];
      product.suggestions = product.suggestions || [];
      product.packs = product.packs || [];

      product.supplements = [...product.supplements, ...supplementsData];
      product.suggestions = [...product.suggestions, ...suggestionsData];
      product.packs = [...product.packs, ...packsData];

      storageCart = {
        ...storageCart,
        products: [...storageCart.products, product],
        count: storageCart.count + quant,
      };
    }
    // Save the updated cart state to localStorage
    localStorage.setItem('cart', JSON.stringify(storageCart));
    // dipatch action to synchronize store.
    dispatch(addToCart(storageCart));
    navigate(-1); // Goes back to the previous page
  };

  useEffect(() => {
    if (product) {
      setTmpPlusProduct({
        price: product?.price,
        ...product
      });
    }
  }, [product]);

  // debug with useEffect 
  useEffect(() => {
    if (tmpPlusProduct && quantityProduct) {
      console.log("Total test :", countAllTotalsOfSingleProduct(tmpPlusProduct, quantityProduct));
      const resultTotal = countAllTotalsOfSingleProduct(tmpPlusProduct, quantityProduct);
      if (resultTotal) {
        setCountAllTotalOfSingleProduct(resultTotal)
      }
    }
  }, [product, tmpPlusProduct])

  return (
    <div>
      <div className="card" style={{ width: "370px", borderRadius: "15px", padding: "20px" }}>
        <img src={`${API_URL}/images/${product.image}`} class="card-img-top" alt="product-single" />
        <div class="card-body" style={{ padding: 0, marginTop: "10px" }}>
          <div>
            <h3 style={{ fontSize: 19, fontWeight: 600 }}>{product.name}</h3>
            <p>
              <strong>{product.price + " DH"} </strong>
            </p>
          </div>
          <p style={{ fontSize: 15, fontWeight: 400, color: "#000000A1" }}>{product.description}</p>
        </div>
        {/* START Here add Section(pack & supplements & suggestions) */}
        <div className="section_product_propositions">
          <PacksProductSection
            selectedProduct={product}
            handleCheckedPacksProp={handleCheckedPacks}
          />
          <SupplementsProduct
            selectedProduct={product}
            handleCheckedSupplimentsProp={handleCheckedSuppliments}
          />
          <SuggestionsProduct
            selectedProduct={product}
            handleCheckedSuggestionsProp={handleCheckedSuggestions}
          />
        </div>
        {/* END Section(pack & supplements & suggestions) */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "50px" }}>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: 'solid black 1px',
            borderRadius: "10px",
            width: "270px",
            padding: "10px"
          }}>
            <div style={{ cursor: "pointer" }} onClick={decrementQnt} >
              <img src="/images/icon_decrement.svg" width={35} height={35} />
            </div>
            <div>
              <h3>{quantityProduct}</h3>
            </div>
            <div style={{ cursor: "pointer" }} onClick={incrementQnt} >
              <img src="/images/icon_increment.svg" width={35} height={35} />
            </div>
          </div>
          <div style={{ marginTop: "25px", textAlign: 'center' }}>
            <button
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: '17px',
                paddingBottom: '17px',
                border: "none",
                fontSize: 19,
                fontWeight: 500,
                background: "linear-gradient(to right, #FCC546, #FDD681)",
                color: '#1E1E1E',
              }}
              type="button" class="btn btn-primary btn-lg"
              onClick={() => addProductToCart(product, quantityProduct)}
            >
              {/* Ajouter au panier {`${formatPrice(product.price)} Dh`} */}
              Ajouter au panier {countAllTotalOfSingleProduct && `${formatPrice(countAllTotalOfSingleProduct)} Dh`}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
