import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import GenericDropDown from './custom/GenericDropDown';
import RegisterModal from '../../components/auth/RegisterModal';
import LoginModal from '../../components/auth/LoginModal';
import ForgetPasswordModal from '../../components/auth/ForgetPasswordModal';
import ResetNewPasswordModal from '../../components/auth/ResetNewPasswordModal';

export default function Header() {  
  const {count} = useSelector((state) => state.cart.cartItems);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isCartRoute = location.pathname === '/cart';
  const isPaymentRoute = location.pathname === '/payment';
  const isPaymentProcessRoute = location.pathname === '/payment-process';
  const isPourboireRoute = location.pathname === '/selected-products-topay'

  const [showModalRegisterClient, setshowModalRegisterClient] =useState(false)
  const [showModalLoginClient, setshowModalLoginClient] =useState(false)
  const [showModalForgetPassword, setShowModalForgetPassword] =useState(false)
  const [showModalResetNewPassword, setShowModalResetNewPassword] =useState(false)

  const tokenResetNewPassword = searchParams.get('token');

  // Check if the URL contains isforgetpass=y
  useEffect(() => {
    if (searchParams.get('isforgetpass') === 'y') {
      setShowModalResetNewPassword(true);
    }
  },[searchParams]);

  return (
          <div className="container-fluid bg-white" 
              style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems:'center',
                  height: '80px',
                  position: 'sticky',
                  top: '0',
                  zIndex: 1000,
              }}
          >
            <div style={{ flex: 1, textAlign: 'left', display: 'flex', alignItems: 'center' }}>
              {
                (isPaymentRoute || isPaymentProcessRoute || isPourboireRoute) && (
                    <img 
                      src="/images/icon-arrow-left.svg" 
                      alt="icon-arrow-left"
                      style={{cursor: 'pointer'}}
                      onClick={() => navigate(-1)}
                    />
                )
              }
              <GenericDropDown
                setshowModalRegisterClient={setshowModalRegisterClient}
                setshowModalLoginClient={setshowModalLoginClient}
              />
              {/* START REGISTER MODAL */}
               {
                  showModalRegisterClient && (
                      <RegisterModal
                        title="Bienvenue sur"
                        setshowModalRegisterClient={setshowModalRegisterClient}
                        setshowModalLoginClient={setshowModalLoginClient}
                      />
                  )
                }
              {/* END REGISTER MODAL */}
              {/* START LOGIN MODAL */}
               {
                  showModalLoginClient && (
                      <LoginModal
                        title="Connectez-vous"
                        setshowModalLoginClient={setshowModalLoginClient}
                        setShowModalForgetPassword={setShowModalForgetPassword}
                      />
                  )
                }
              {/* END LOGIN MODAL */}
              {/* START FORGETPASSWORD MODAL */}
              {
                  showModalForgetPassword && (
                      <ForgetPasswordModal
                        title="Mot de passe oublié ?"
                        setShowModalForgetPassword={setShowModalForgetPassword}
                      />
                  )
                }
              {/* END FORGETPASSWORD MODAL */}
              {/* START RESET NEWPASSWORD MODAL */}
              {
                  showModalResetNewPassword && (
                      <ResetNewPasswordModal
                        title="Nouveau mot de passe"
                        setShowModalResetNewPassword={setShowModalResetNewPassword}
                        tokenResetNewPassword={tokenResetNewPassword && tokenResetNewPassword}
                        setshowModalLoginClient={setshowModalLoginClient}
                      />
                  )
                }
              {/* END RESET NEWPASSWORD MODAL */}
            </div>
            <div 
              style={{
                cursor: 'pointer',
                flex: '1',
                textAlign: 'center',
              }}
            >
              <img 
                src="/images/tablati_logo.png" 
                alt="tablati-logo"
                width={100}
                height={37}
                onClick={() => navigate('/')}
              />
            </div>
            <div style={{ flex: 1, textAlign: 'right' }}>
          {
            !isCartRoute && !isPaymentRoute && !isPaymentProcessRoute && (
              <>
                <img 
                    src="/images/cart_icon.svg" 
                    style = {{
                        border: 'solid white 1px',
                        borderRadius: '50%',
                        backgroundColor: '#FCC54630',
                        padding:"10px",
                        cursor:"pointer",
                    }} 
                    onClick={() => navigate('/cart')}
                    alt="cart-icon"
                />
                <div
                  style={{
                    height:"20px",
                    width:"20px",
                    position: 'absolute',
                    top: '8px',
                    right: '6px',
                    backgroundColor: count > 0 ? '#FF9500' : 'none',
                    color: 'white',
                    borderRadius: '50%',
                    padding: '5px',
                    textAlign:"center",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                }}
                >
                  {count > 0 && count}
                </div>
              </>
              
              )
            }
      </div>
    </div>
  )
}
