import React, { useState } from 'react'
import { verifyMiamusCouponService, verifyValidityCouponService } from '../common/apiServices/coupon/coupon.service';

export default function InsertCouponModal({
    title,
    setShowModalInsertCoupon,
    setIsCouponValid,
    setCouponData
}) {

    const [couponValue, setCouponValue] = useState('');
    const [messageCouponResult, setMessageCouponResult] = useState(false);
    const handleChangeCoupon = (event) => {
        console.log("event.target.value :", event.target.value)
        setCouponValue(event.target.value)
    }

    const handleSubmitCouponValue = (event) => {
        event.preventDefault();
        // const bodyObj = {
        //     couponName: couponValue
        // }
        // check value prefix if includes 'Miamus to call MiamusCOuponService'
        if (couponValue.split('_')[0] === "Miamus") {
            const bodyObjMiamus = {
                coupon: couponValue
            }
            verifyMiamusCouponService(bodyObjMiamus)
                .then(data => {
                    switch (data.code) {
                        case 'NOTFOUND':
                            setMessageCouponResult('Coupon incorrect !');
                            break;
                        case 'EXPIRED':
                            setMessageCouponResult('Coupon expiré !');
                            break;
                        case 'USED':
                            setMessageCouponResult('Coupon déja utilisé !');
                            break;
                        case 'VALID':
                            setMessageCouponResult('Coupon valide !');
                            // the coupon is Active
                            setShowModalInsertCoupon(false);
                            setIsCouponValid(true);
                            setCouponData(prevData => ({
                                ...prevData, // If prevData exists, it will spread its values
                                amountPromotion: data?.discount,
                                name_coupon: data?.coupon
                            }));
                            break;
                        default:
                            // Do nothing and just break the switch
                            break;
                    }
                })
                .catch(err => console.log(err))
        } else {
            const bodyObj = {
                couponName: couponValue
            }
            verifyValidityCouponService(bodyObj)
                .then(data => {
                    if (data.code === 'COUPON_NOT_FOUND' || data.code === 'COUPON_OFF') {
                        setMessageCouponResult('Ce coupon ne semble pas valide. Essayez à nouveau ! ')
                    }
                    else {
                        // the coupon is Active
                        setShowModalInsertCoupon(false);
                        setIsCouponValid(true);
                        setCouponData(data?.couponData)
                    }
                })
                .catch(err => console.log(err))
        }
    }
    return (
        <div
            className="modal fade show"
            id="shareModal"
            tabindex="-1"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
            style={{
                // this stye what make modal display
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
        >
            <div class="modal-dialog"
                style={{
                    position: "fixed",
                    bottom: -15,
                    left: "49%",
                    transform: "translateX(-50%)",
                    width: "100%",
                }}
            >
                <div class="modal-content"
                    style={{ borderColor: "none", }}
                >
                    <div class="modal-header">
                        <h5
                            class="modal-title text tex-center"
                            id="shareModalLabel"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: "22px",
                                fontWeight: "600",
                            }}
                        >{title}</h5>
                        <img
                            src="images/close-icon.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowModalInsertCoupon(false)}
                        />
                    </div>
                    <div class="modal-body">
                        <div class="d-grid gap-2">
                            <form onSubmit={handleSubmitCouponValue}>
                                <div className='mb-2'>
                                    <label htmlFor='coupon-name'></label>
                                    <input type='text' className='form-control' onChange={handleChangeCoupon} />
                                </div>
                                <div>
                                    {messageCouponResult && <p
                                        className='text text-danger'
                                        style={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                            marginLeft: 3
                                        }}
                                        onClick={() => setMessageCouponResult(false)}>
                                        {messageCouponResult}
                                    </p>
                                    }
                                </div>
                                <div class="d-grid gap-2 col-6 mx-auto">
                                    <button class=""
                                        type="submit"
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            fontFamily: "Poppins",
                                            borderRadius: "20px",
                                            marginTop: "5px",
                                            padding: "10px",
                                            background: "#000000",
                                            opacity: couponValue.length === 0 ? 0.7 : 1,
                                            color: '#FFFFFF',
                                        }}
                                        // onClick={handleSharedOrdersByChoiceOption}
                                        disabled={couponValue === ''}
                                    >Valider </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
