import Header from '../../common/components/Header'
import SubHeader from '../../common/components/SubHeader'
import { isAuthenticated } from '../../common/helpers/authentication'

export default function UserAccountInfos() {

    const { user } = isAuthenticated();

    return (
        <div>
            <Header />
            <SubHeader />
            {/* Add User Infos */}
            <div className=' p-3'>
                <p className='text text-warning fw-bold'>MES INFOS</p>
                <form>
                    <div className='mb-3'>
                        <label htmlFor='firstNameId'><b>Prénom*</b></label>
                        <input
                            type='text'
                            className='form-control'
                            name="firstName"
                            id="firstNameId"
                            disabled
                            value={user && user?.firstName}
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='lastNameId'><b>Nom*</b></label>
                        <input type='text' className='form-control' name="lastName" id="lastNameId"
                            disabled
                            value={user && user?.lastName}
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='emailId'><b>Adresse e-mail*</b></label>
                        <input type='email' className='form-control focus-warning'
                            name="email" id="emailId"
                            disabled
                            value={user && user?.email}
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='phoneId'><b>Téléphone</b></label>
                        <input type='text' className='form-control' name="phone" id='phoneId'
                            disabled
                            value={user && user?.phone}
                        />
                    </div>
                    <div className='mb-3'>
                        <label htmlFor='passwordId'><b>Mot de passe*</b></label>
                        <input type='password' className='form-control' name="password"
                            id="passwordId"
                            disabled
                            value={user && user?.password}
                        />
                    </div>
                    <div class="d-flex justify-content-center">
                        <button type="submit" class="btn btn-warning m-1 fw-semibold"
                        // onClick={() => setshowModalRegisterClient(false)}
                        >
                            Confirmer
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
