import Header from '../../common/components/Header'
import SubHeader from '../../common/components/SubHeader'

export default function Mymiamus() {
    const spanCommonStyle = {
        fontSize: 17,
        fontWeight: 600,
        flex: 1, // to ensure same width for each item
        textAlign: 'center'
    }
    return (
        <div>
            <Header />
            <SubHeader />
            {/* Add User Infos */}
            <div className='container' style={{ paddingBottom: '10px' }}>
            <div className='bg bg-warning' style={{
                padding: 8,
                borderRadius: 10
               }}>
                <p style={{
                    fontSize: 13,
                    fontWeight: 600,
                    margin: 0
                }}>Mes Miamus</p>
                <p style={{
                    fontSize: 14,
                    fontWeight: 700,
                    margin: 0
                }}>0</p>
               </div>
               <div>
                {/* Labels */}
                <div className='mt-2' 
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <span style={spanCommonStyle}>0</span>
                    <span style={spanCommonStyle}>-25 %</span>
                    <span style={spanCommonStyle}>-50 %</span>
                </div>
                <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{height: "5px"}}>
                    <div class="progress-bar bg-warning" style={{width: "25%"}}></div>
                </div>
                <div className='mt-2' 
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <span style={spanCommonStyle}>Miamus</span>
                    <span style={spanCommonStyle}>1000</span>
                    <span style={spanCommonStyle}>2000</span>
                </div>
               </div>
               <div className='bg bg-warning mt-2' style={{
                padding: 8,
                borderRadius: 10,
                marginRight: 8,
                marginLeft: 8
               }}>
                <p style={{
                    fontSize: 16,
                    fontWeight: 600,
                    margin: 0
                }}>Mes Miamus</p>
                <p style={{
                    fontSize: 20,
                    fontWeight: 700,
                    margin: 0
                }}>0</p>
               </div>
               <h3 style={{
                fontSize: 16,
                fontWeight: 600,
                color: '#150C1B',
                marginTop: 10
               }}>Explorez vos avantages</h3>
               <div style={{
                border: 'solid #E5E5E5 1.5px',
                padding: 15,
                // boxShadow:'1px lightgray'
               }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <img src="/images/account/discount_icon.png"/>
                        <h4 style={{
                            fontSize: 14,
                            fontWeight: 700,
                            color: '#150C1B',
                            marginLeft: 5
                        }}>Des réductions  alléchantes</h4>
                    </div>
                    <img src='/images/right_icon.svg'/>
                </div>
                <p style={{fontSize: 12, fontWeight: 600, color: '#969696', marginTop: 5}}>
                    Plus que <span className='text text-dark fw-bold'>1000 Miamus</span> avant de recevoir <span className='text text-dark fw-bold'>100 Dhs d’économie</span> à utiliser dans l’un de nos <span className='text text-dark fw-bold'>30 restaurants partenaires</span>
                </p>
                 {/* Labels */}
                 <div className='mt-2' 
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <span style={spanCommonStyle}>0</span>
                    <span style={spanCommonStyle}>-25 %</span>
                    <span style={spanCommonStyle}>-50 %</span>
                </div>
                <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{height: "5px"}}>
                    <div class="progress-bar bg-warning" style={{width: "25%"}}></div>
                </div>
                <div className='mt-2' 
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <span style={spanCommonStyle}>Miamus</span>
                    <span style={spanCommonStyle}>1000</span>
                    <span style={spanCommonStyle}>2000</span>
                </div>
               </div>
               <h3 style={{
                fontSize: 16,
                fontWeight: 600,
                color: '#150C1B',
                marginTop: 10
               }}>Votre Activité</h3>
               <div style={{
                border: 'solid #E5E5E5 1.5px',
                padding: 15,
                marginBottom: 30
               }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <img src="/images/account/discount_icon.png"/>
                        <h4 style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: '#150C1B',
                            marginLeft: 5
                        }}>+100 MIAMUS</h4>
                    </div>
                    <p style={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: '#969696'
                    }}>Il y a 22 heures</p>
                </div>
                <p style={{fontSize: 12, fontWeight: 600, color: '#969696', marginTop: 5}}>
                    Vous avez effectué un paiement via Tablati
                </p>
               </div>
            </div>
        </div>
    )
}
