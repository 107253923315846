import { API_URL } from "../../../env"

export const clientRegisterService = (userAuth) => {
    return fetch(`${API_URL}/api/auth/clients-account/register`,{
      method : 'POST',
      headers : {
        'Content-Type': 'application/json',
      },
      body : JSON.stringify(userAuth)
    })
    .then((res) => res.json())
    .catch((err) => err)
}
export const clientLoginService = (userAuth) => {
    return fetch(`${API_URL}/api/auth/clients-account/login`,{
      method : 'POST',
      headers : {
        'Content-Type': 'application/json',
      },
      body : JSON.stringify(userAuth)
    }) 
    .then((res) => res.json())
    .catch((err) => err)
}

export const clientLogoutService = () => {
  return fetch(`${API_URL}/api/auth/clients-account/logout`)
}

export const forgetPasswordEmailService = async (bodyData) => {
  return fetch(`${API_URL}/api/auth/clients-account/forget-password-request`,{
    method : 'POST',
    headers : {
      'Content-Type': 'application/json',
    },
    body : JSON.stringify(bodyData)
  }) 
  .then((res) => res.json())
  .catch((err) => err)
}

export const resetNewPasswordService = async (bodyData) => {
  return fetch(`${API_URL}/api/auth/clients-account/reset-new-password`,{
    method : 'POST',
    headers : {
      'Content-Type': 'application/json',
    },
    body : JSON.stringify(bodyData)
  }) 
  .then((res) => res.json())
  .catch((err) => err)
}