import React, { useState } from 'react'
import './Register.style.css'
import { clientLoginService } from '../../common/apiServices/auth/auth.service'
import { setLocalStorage } from './../../common/helpers/authentication';

export default function LoginModal({
    title,
    setshowModalLoginClient,
    setShowModalForgetPassword
}) {

    const [clientLoginData, setClientLoginData] = useState({
        email: "",
        password: "",
    })
    const [msgErrorLogin, setMsgErrorLogin] = useState("")

    const handleChangeLoginClient = (event) => {
        const { name, value } = event.target;
        // For other fields, just update the value as usual
        setClientLoginData({
            ...clientLoginData,
            [name]: value
        });
    }

    const handleSubmitLoginClient = (event) => {
        event.preventDefault();
        console.log("clientLoginData :", clientLoginData)
        // call api service Login
        clientLoginService(clientLoginData)
        .then((data) => {
            if(data && data.code !== "BAD_REQUEST"){
                if(data.code === 'INCORRECT'){
                    setMsgErrorLogin("Email ou password incorrect !!")
                }else{
                    setClientLoginData({
                        email: "",
                        password: "",
                    })
                    setMsgErrorLogin("")
                    // store in localSorage authData & close Login Modal bby active loggedMode
                    setLocalStorage(data);
                    setshowModalLoginClient(false)
                }
            }
        })
        .catch((err) => console.log(err))
    }
    return (
        <div
            className="modal fade show"
            id="shareModal"
            tabindex="-1"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
            style={{
                // this stye what make modal display
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div class="modal-dialog"
                style={{
                    maxWidth: '80vw',
                    width: '400px',
                    margin: "auto",
                }}
            >
                <div class="modal-content"
                    style={{ borderColor: "none", }}
                >
                    <div class="modal-header">
                        <h5
                            class="modal-title"
                            id="shareModalLabel"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: 23,
                                fontWeight: 600
                            }}
                        >{title}</h5>
                        <img
                            src="images/close-icon.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => setshowModalLoginClient(false)}
                        />
                    </div>
                    <div class="modal-body">
                        <div class="d-grid gap-2">
                            <form onSubmit={handleSubmitLoginClient}>
                                <div className='mb-3'>
                                    <label className='fw-semibold' htmlFor='emailId'>Adresse e-mail*</label>
                                    <input type='email' className='form-control focus-warning' name="email" required id="emailId" value={clientLoginData?.email}
                                        onChange={handleChangeLoginClient}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label className='fw-semibold' htmlFor='passwordId'>Mot de passe*</label>
                                    <input type='password' className='form-control' name="password" required id="passwordId" value={clientLoginData?.password}
                                        onChange={handleChangeLoginClient}
                                    />
                                </div>
                                <div className='forgetPass'>
                                   <p style={{
                                    fontSize: 12,
                                    fontWeight: 600,
                                    textDecoration: 'underline'
                                   }}
                                   onClick={() => {
                                    setshowModalLoginClient(false)
                                    setShowModalForgetPassword(true)
                                   }}
                                   > Mot de passe oublié ?</p>
                                </div>
                                {
                                    msgErrorLogin && <p className='text text-danger text-small'>{msgErrorLogin}</p>
                                }
                                <div class="d-flex justify-content-center">
                                    <button type="submit" class="btn btn-warning m-1 fw-semibold">
                                        Se connecter
                                    </button>
                                    <button type="button" class="btn btn-outline-warning m-1 fw-semibold"
                                        onClick={() => setshowModalLoginClient(false)}
                                    >Annuler</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
